const ConstantStrings = {
  FINISHED: "Hoàn thành",
  GRADEID: "63e52faf42ec4c0907d63e14",
  IKMC_ID: "65f1161405ca2b2f1f9db971",
  HK2_ID: "65f266c978e5e505a5441505",
  HK1_ID: "671afe454743f4e3289eb28d",
  ROAD_MAP_LV1: "63e52faf42ec4c0907d63e14",
  ROAD_MAP_LV2: "63e530a500953d155a8fd0bd",
  ROAD_MAP_LV3: "63e530ac5bbafe81be69d40d",
  ROAD_MAP_LV4: "63e530b32acd470b84e46c95",
  ROAD_MAP_LV5: "63e530baf3a5d5e917183b02",
  SLIDE: "Slide",
  SECTION: "Section",
  TEMPLATE_CONTENT: "Template Content",
  SUBMIT: "Trả lời",
  NEXT_QUESTION: "Câu sau",
  NEXT: "Tiếp theo",
  PREVIOUS: "Trước",
  PREVIOUS_QUESTION: "Câu trước",
  SKIP: "Bỏ qua",
  SKIP_DAILY_QUIZ_EVENT: "Bỏ qua câu này",
  RETRY: "Làm Lại",
  RESET: "Reset",
  SHOW_EXPLANATION: "xem giải thích",
  SHOW_EXPLANATION_PRACTICE: "xem lời giải",
  SHOW_QUESTION: "xem bài làm",
  REQUEST_FILL_IN_ALL_GAPS: "Please fill in all gaps",
  MULTIPLIERIMAGE: 0.25,
  BASE_REM: 16,
  OTP_IS_INCORRECT: "Sai mã OPT",
  DO_NOT_LEAVE_BLANK: "Vui lòng không để trống",
  PASSWORD_INCORRECT: "Mật khẩu không khớp",
  PASSWORD_CHECK:
    "Mật khẩu phải có ít nhất 6 ký tự. Mật khẩu phải bao gồm chữ và số.",
  PASSWORD_NEW: "Mật khẩu mới không được giống mật khẩu cũ",
  TIME_OUT: "Hết thời gian vui lòng bấm gửi lại để nhận mã OTP mới",
  VNPAY_CONTENT: "cổng thanh toán VNPAY",
  DIRECT_TRANFER_CONTENT:
    "Thanh toán bằng cách chuyển trực tiếp vào số tài khoản của KURIO sau đó liên lạc với bộ phận chăm sóc khách hàng để xác nhận.",
  SERVICE_CONTENT:
    "Truy cập toàn bộ bài giảng, bài tập và các mục trong phần hoạt động trong ",
  UPGRADE_SUCCESS: "Nâng cấp Tài khoản VIP thành công!",
  PAYMENT_FAILED:
    "Vui lòng thanh toán bằng cách chuyển khoản trực tiếp hoặc liên hệ với bộ phận chăm sóc khách hàng để được tư vấn.",
  PAYMENT_FAILED_KURIO:
    "Vui lòng thử lại hoặc liên hệ với bộ phân chăm sóc khách hàng của Kurio để được hỗ trợ.",
  DATA_SITE_KEY: "6LfNQnckAAAAAConbJxXRdzYPGv_FnYMajhagYts",
  HIDE_GRADES: ["Lớp 6", "Lớp 7", "Lớp 8", "Lớp 9"],
  ARRAY_LANDING_PAGE_PATHS: [
    "/payment-policy",
    "/privacy-policy",
    "/return-policy",
    "/payment-methods",
    "/policy",
    "/kho-tro-choi",
    "/kho-tro-choi/day-so-bi-an",
    "/kho-tro-choi/to-mau",
    "/kho-tro-choi/bai-toan-noi",
    "/flutter/",
    "/elocalibration",
    "/ios",
    "/android",
    "/policy",
    "/register-mobile",
    "/payment-success",
    "/feedback2025",
  ],
  IMAGE_URL:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_IMAGE_URL
      : process.env.REACT_APP_IMAGE_URL,
  GA_MEASUREMENT_ID:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_GA_MEASUREMENT_ID
      : process.env.REACT_APP_GA_MEASUREMENT_ID,

  GRADES_USE_CATEGORY_ROADMAP_1: ["Lớp 1", "Lớp 2", "Lớp 3", "Lớp 4", "Lớp 5"],
  GRADES_USE_CATEGORY_ROADMAP_2: ["Lớp 6", "Lớp 7", "Lớp 8", "Lớp 9"],
  DEFAULT_ACHIEVEMENT_IMAGE: "/images/achievement/achievement-default.png",
  DEFAULT_IMAGE: "/images/landing-page/logo-mobile.svg",
};

export default ConstantStrings;
