const FeedbackRedirect = () => {
  return (
    <>
      <iframe
        title="doc-google"
        src="https://docs.google.com/forms/d/e/1FAIpQLSe1NL2lLoYfkJTU5UU3fvFBfUJdoCR3uyf3X51UpZlq2pHiMA/viewform?fbzx=-527458576199714447"
        width="100%"
        style={{ minHeight: "100vh" }}
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </>
  );
};

export default FeedbackRedirect;
