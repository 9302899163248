import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import ProblemSet from "../../../components/examPreparation/ProblemSet";
import RoadMapExam from "../../../components/examPreparation/RoadMapExam";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import ConstantStrings from "../../../constants/ConstantStrings";
import { getGradeUserInfo } from "../../../reducers/gradesSlice";
import {
  clearStateRoadMap,
  getListRoadMapCategories,
  getProblemSetQuestion,
  getProblemSetSolution,
  getRoadMap,
  getRoadMapExamQuestion,
  getRoadMapExamSolution,
  getTakeProblemSetId,
  getTakeRoadMapExamId,
  resetRoadAMap,
  restartProblemSet,
} from "../../../reducers/roadmapSlice";
import {
  checkAndGetAnonymousUserId,
  checkPremium,
  checkRouteCategoryRoadMap,
  SCREEN_PRACTICE,
} from "../../../templates/helper/CheckAndGetAnonymousUserId";
import { getAllImage } from "../../../templates/helper/getAllImages";
import { LoadingImage } from "../../../templates/helper/LoadingImage";
import RoadMapExamMainWrapper from "../../exam-preparation/bigExam/RoadMapExamMainWrapper";
import ProblemSetMainWrapper from "../../exam-preparation/roadmap-problem-set/ProblemSetMainWrapper";

const ExamPreparationHK2 = () => {
  const { listUserInfo: userInfo } = useSelector((state) => state.grades);
  const {
    examsRoadMap,
    takeRoadMapId,
    takeProblemSetId,
    questions: questionsRoadMap,
    categories,
    listRoadMap,
  } = useSelector((state) => state.roadMap);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");

  const [showIntroduction, setShowIntroduction] = useState(false);
  const [showContinueToPractice, setShowContinueToPractice] = useState(false);
  // const [apiCalled, setApiCalled] = useState(false);
  const [showBigExam, setShowBigExam] = useState(
    localStorage.getItem("takeRoadMapExamId") ? true : false
  );

  const [showPopupProblemSet, setShowPopupProblemSet] = useState(
    localStorage.getItem("takeRoadMapProblemSetId") ? true : false
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const [isClick, setIsClick] = useState(false);

  const [loadingImage, setLoadingImage] = useState(false);

  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const backToHome = () => {
    localStorage.removeItem("takeRoadMapExamId");
    localStorage.removeItem("takeRoadMapProblemSetId");
    localStorage.removeItem("submittedProblemSet");
    localStorage.removeItem("submitted");
    localStorage.removeItem("correctAnswers");

    if (token) {
      navigate("/semester-exam-preparation");
    } else {
      navigate("/");
    }
  };

  const handleShowIntroduction = () => {
    setShowIntroduction(true);
  };

  const handleSetShowContinueToPractice = () => {
    // const categoryRoadmap = !ConstantStrings.HIDE_GRADES.includes(gradeName)
    //   ? ConstantStrings.CATEGORY_ROADMAP_1
    //   : ConstantStrings.CATEGORY_ROADMAP_2;
    dispatch(
      getRoadMap({
        gradeId: cookies.get("gradeId"),
        isAnonymous: token ? false : true,
        anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        categoryId: checkRouteCategoryRoadMap(window.location.pathname),
      })
    );

    setShowContinueToPractice(!showContinueToPractice);
  };

  const handleReset = () => {
    dispatch(clearStateRoadMap());

    dispatch(
      resetRoadAMap({
        roadMapId: listRoadMap._id,
        isAnonymous: token ? false : true,
        anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
      })
    );
    // const categoryRoadmap = !ConstantStrings.HIDE_GRADES.includes(gradeName)
    //   ? ConstantStrings.CATEGORY_ROADMAP_1
    //   : ConstantStrings.CATEGORY_ROADMAP_2;
    dispatch(
      getRoadMap({
        gradeId: cookies.get("gradeId"),
        isAnonymous: token ? false : true,
        anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        categoryId: checkRouteCategoryRoadMap(window.location.pathname),
      })
    );
    setShowContinueToPractice(!showContinueToPractice);
  };

  const removePopup = () => {
    setShowIntroduction(false);
  };
  // const checkPremium = (userInfo) => {
  //   var result = false;
  //   if (
  //     (userInfo?.premiumFrom !== null &&
  //       userInfo?.premiumTo !== null &&
  //       new Date(userInfo?.premiumFrom).getTime() <= new Date().getTime() &&
  //       new Date(userInfo?.premiumTo).getTime() > new Date().getTime()) ||
  //     (userInfo?.premiumTo !== null &&
  //       new Date(userInfo?.premiumTo).getTime() > new Date().getTime())
  //   ) {
  //     result = true;
  //   }

  //   return result;
  // };

  const handleShowBigExam = (
    roadMapExamId,
    takeRoadMapExamId,
    submitted,
    name
  ) => {
    localStorage.setItem("nameExam", name);
    if (takeRoadMapExamId === null) {
      dispatch(
        getTakeRoadMapExamId({
          roadmapId: listRoadMap?._id,
          examId: roadMapExamId,
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        })
      );
    } else {
      localStorage.setItem("takeRoadMapExamId", takeRoadMapExamId);

      if (submitted) {
        localStorage.setItem("submitted", submitted);
        dispatch(
          getRoadMapExamSolution({
            examId: takeRoadMapExamId,
            page: 1,
            size: 50,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
      } else {
        dispatch(
          getRoadMapExamQuestion({
            examId: takeRoadMapExamId,
            page: 1,
            size: 50,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
      }

      setLoadingImage(true);
    }
    setShowBigExam(true);
  };

  // ProblemSet

  const showPopUpProblemSet = (
    roadMapId,
    roadMapExamId,
    proBlemSetId,
    takeRoadMapProblemSetId,
    correctAnswers,
    submitted,
    showSolutionProblemSet
  ) => {
    if (questionsRoadMap === null && !submitted && !takeRoadMapProblemSetId) {
      localStorage.removeItem("correctAnswers");
      dispatch(
        getTakeProblemSetId({
          roadmapId: roadMapId,
          examId: roadMapExamId,
          problemSetId: proBlemSetId,
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        })
      );
      setShowPopupProblemSet(true);
    } else {
      localStorage.setItem("takeRoadMapProblemSetId", takeRoadMapProblemSetId);

      if (submitted && showSolutionProblemSet) {
        localStorage.setItem("submittedProblemSet", submitted);
        localStorage.setItem("correctAnswers", correctAnswers);

        dispatch(
          getProblemSetSolution({
            problemSetId: takeRoadMapProblemSetId,
            page: 1,
            size: 50,
            isAnonymous: token ? false : true,
            anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          })
        );
        setShowPopupProblemSet(true);
      } else {
        if (submitted && !showSolutionProblemSet) {
          localStorage.setItem("correctAnswers", correctAnswers);

          dispatch(
            restartProblemSet({
              problemSetId: takeRoadMapProblemSetId,
              isAnonymous: token ? false : true,
              anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
            })
          ).then(() => {
            dispatch(
              getProblemSetQuestion({
                problemSetId: takeRoadMapProblemSetId,
                page: 1,
                size: 50,
                isAnonymous: token ? false : true,
                anonymousUserId: token
                  ? undefined
                  : checkAndGetAnonymousUserId(),
              })
            );

            setShowPopupProblemSet(true);
          });
        } else {
          dispatch(
            getProblemSetQuestion({
              problemSetId: takeRoadMapProblemSetId,
              page: 1,
              size: 50,
              isAnonymous: token ? false : true,
              anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
            })
          );
          setShowPopupProblemSet(true);
        }
        localStorage.setItem("submittedProblemSet", false);
      }
    }
  };

  useEffect(() => {
    if (userInfo === null && token) {
      dispatch(getGradeUserInfo());
    }
    if (categories === null && listRoadMap === null) {
      dispatch(getListRoadMapCategories());
    }
    if (
      showBigExam &&
      !showPopupProblemSet &&
      takeRoadMapId !== null &&
      questionsRoadMap !== null &&
      questionsRoadMap?.length === 0 &&
      !localStorage.getItem("submitted")
    ) {
      dispatch(
        getRoadMapExamQuestion({
          examId: takeRoadMapId,
          page: 1,
          size: 50,
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        })
      );
      setLoadingImage(true);
    }

    if (
      !showBigExam &&
      showPopupProblemSet &&
      takeProblemSetId !== null &&
      questionsRoadMap !== null &&
      questionsRoadMap?.length === 0 &&
      !localStorage.getItem("submittedProblemSet")
    ) {
      dispatch(
        getProblemSetQuestion({
          problemSetId: localStorage.getItem("takeRoadMapProblemSetId"),
          page: 1,
          size: 50,
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
        })
      );
      setLoadingImage(true);
    }

    if (!token) {
      dispatch(
        getRoadMap({
          gradeId: localStorage.getItem("roadMapId")
            ? localStorage.getItem("roadMapId")
            : ConstantStrings.ROAD_MAP_LV1,
          isAnonymous: token ? false : true,
          anonymousUserId: checkAndGetAnonymousUserId(),
          categoryId: checkRouteCategoryRoadMap(window.location.pathname),
        })
      );
    } else {
      dispatch(
        getRoadMap({
          gradeId: cookies.get("gradeId"),
          isAnonymous: token ? false : true,
          anonymousUserId: token ? undefined : checkAndGetAnonymousUserId(),
          categoryId: checkRouteCategoryRoadMap(window.location.pathname),
        })
      );
    }
    const closeDropdown = () => {
      if (showDropdown) {
        setIsClick(true);
      }
      if (isClick) {
        setShowDropdown(false);
        setIsClick(false);
      }
    };
    window.addEventListener("click", closeDropdown);
    return () => window.removeEventListener("click", closeDropdown);
  }, [token, listRoadMap, isClick, showDropdown, categories, userInfo]);

  if (
    loadingImage &&
    questionsRoadMap !== null &&
    questionsRoadMap?.length > 0
  ) {
    const result = getAllImage(questionsRoadMap, "EXAM");
    if (result && result?.length > 0 && !localStorage.getItem("currentIndex")) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }

  return (
    <BackgroundImageFromUrl
      className={`${
        showContinueToPractice
          ? "has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center"
          : ""
      }`}
      objectId={
        !showContinueToPractice
          ? "admin/frontend-images/exam-preparation/bg-exam-preparationHK2.svg"
          : ""
      }
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundImage: !showContinueToPractice
        //   ? "url('/images/exam-preparation/background-exam-preparation.svg')"
        //   : "",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      {showIntroduction && (
        <div className="modal is-active" style={{ opacity: 1, zIndex: 100 }}>
          <div
            className=" modal-background"
            style={{ opacity: 0.8 }}
            onClick={removePopup}
          ></div>
          <div
            className="is-relative has-text-kurio-main-color-black is-relative has-background-kurio-main-color-white is-flex is-flex-direction-column is-flex-gap-5 is-align-items-center is-justify-content-center"
            style={{
              width: "50rem",
              padding: "4rem",
              borderRadius: "2rem",
              maxHeight: "42rem",
              marginTop: "8rem",
            }}
          >
            <div
              className="box-privacy-policy is-flex is-flex-direction-column is-flex-gap-4"
              style={{ overflowY: "auto" }}
            >
              <div
                className="px-2 is-flex is-flex-direction-column has-text-kurio-main-color-black"
                style={{
                  fontSize: "0.875rem",
                  gap: "2.5rem",
                }}
              >
                <div
                  className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-weight-bold"
                  style={{ fontSize: "2.25rem", lineHeight: "normal" }}
                >
                  <p>Giới thiệu Tính năng</p>
                  <p className="is-uppercase">ĐỘT PHÁ THI HỌC KỲ</p>
                </div>
                <div
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "150%",
                    fontWeight: 500,
                    gap: "3.5rem",
                  }}
                  className="is-flex is-flex-direction-column"
                >
                  <p>
                    Ôn thi học kỳ chưa bao giờ trở nên đơn giản và hiệu quả như
                    với tính năng <b>Đột Phá Thi Học Kỳ</b> của Kurio, với cơ
                    chế hoạt động đặc biệt:
                  </p>

                  <p>
                    <b className="has-text-kurio-main-color-purple">
                      Bước 1: Đánh giá Trình Độ
                    </b>{" "}
                    <br />{" "}
                    <ul>
                      <li
                        style={{ listStyle: "inside", marginLeft: "0.75rem" }}
                      >
                        Đầu tiên, bạn sẽ tham gia vào một bài kiểm tra, theo
                        dạng một bài thi học kỳ thực tế. Từ đó, Kurio sẽ đánh
                        giá trình độ hiện tại của bạn.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <b className="has-text-kurio-main-color-purple">
                      Bước 2: Tạo Lộ Trình Cá Nhân Hóa
                    </b>{" "}
                    <br />
                    <ul>
                      <li
                        style={{ listStyle: "inside", marginLeft: "0.75rem" }}
                      >
                        Sau khi bạn hoàn thành bài kiểm tra, thuật toán thông
                        minh của Kurio bắt đầu làm việc để tạo ra một lộ trình
                        học tập và kiểm tra cá nhân hóa cho bạn. Bạn sẽ được
                        hướng dẫn và ôn tập theo cách phù hợp nhất với trình độ
                        và mục tiêu luyện thi của mình.
                      </li>
                      <br />
                      <li
                        style={{ listStyle: "inside", marginLeft: "0.75rem" }}
                      >
                        Với lộ trình cá nhân hóa, bạn sẽ học một cách trọng tâm
                        những khái niệm và kỹ năng mà bạn cần cải thiện. Bạn sẽ
                        không bao giờ cảm thấy lạc hướng hoặc quá tải.
                      </li>
                    </ul>
                    <br />
                    <br />
                    <b className="has-text-kurio-main-color-purple">
                      Bước 3: Kiểm tra Đầu ra
                    </b>{" "}
                    <br />
                    <ul>
                      <li
                        style={{ listStyle: "inside", marginLeft: "0.75rem" }}
                      >
                        Sau khi hoàn thành lộ trình, Kurio sẽ tự động tạo một
                        bài kiểm tra đầu ra dành riêng cho bạn. Tại đây, bạn sẽ
                        có cơ hội tự đánh giá sự tiến bộ của mình.
                      </li>
                    </ul>
                  </p>

                  <p>
                    Tính năng Đột phá Thi Học Kỳ của Kurio không chỉ giúp bạn tự
                    tin đối diện với kỳ thi học kỳ mà còn giúp bạn phát triển
                    tình yêu và nâng cao tư duy giải toán.
                    <br /> <br />
                    <b style={{ fontStyle: "italic" }}>
                      Hãy cùng chúng tôi bắt đầu hành trình đột phá điểm số
                      trong kỳ thi học kỳ này!
                    </b>
                  </p>
                </div>

                <div
                  className="is-flex"
                  style={{ gap: "1rem", margin: "0 auto" }}
                >
                  <PrimaryButtonPageLevel
                    style={{ height: "4rem" }}
                    // disabled={!isAgreed}
                    onClick={removePopup}
                  >
                    đóng
                  </PrimaryButtonPageLevel>
                </div>
              </div>
            </div>
            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showContinueToPractice && (
        <div className="is-flex is-flex-direction-column is-flex-gap-3">
          <div
            className="is-clickable is-flex is-flex-gap-3 is-align-items-center has-text-kurio-main-color-black has-text-weight-bold is-uppercase"
            style={{
              fontSize: "0.875rem",
              lineHeight: "normal",
              marginLeft: "6rem",
            }}
            onClick={backToHome}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="14"
              viewBox="0 0 7 14"
              fill="none"
            >
              <path
                d="M5.82866 13.9995C5.53666 13.9995 5.24666 13.8725 5.04866 13.6265L0.22066 7.6265C-0.07734 7.2555 -0.07334 6.7255 0.23166 6.3595L5.23166 0.359495C5.58466 -0.0645046 6.21566 -0.121505 6.64066 0.231495C7.06466 0.584495 7.12166 1.2155 6.76766 1.6395L2.29266 7.0105L6.60766 12.3725C6.95366 12.8025 6.88566 13.4325 6.45466 13.7785C6.27066 13.9275 6.04866 13.9995 5.82866 13.9995Z"
                fill="#0A2A66"
              />
            </svg>
            <p>Quay Lại</p>
          </div>
          <div
            className="is-flex is-justify-content-center is-align-items-center is-flex-gap-8"
            style={{ width: "80rem", minHeight: "100%" }}
          >
            <div
              className="is-flex is-flex-gap-5 is-flex-direction-column is-align-items-flex-start is-flex-shrink-0 has-text-kurio-main-color-black"
              style={{ width: "30rem" }}
            >
              <p
                className="has-text-weight-bold"
                style={{ fontSize: "2.25rem", lineHeight: "normal" }}
              >
                Tiếp tục Ôn luyện
              </p>
              <p style={{ fontSize: "1rem", lineHeight: "normal" }}>
                Hành động này sẽ{" "}
                <b>
                  RESET toàn bộ chuỗi bài học ‘Đường lên đỉnh IKMC 2024’ hiện
                  tại, tạo ra chuỗi bài học mới phù hợp với trình độ của học
                  sinh sau khi học xong chuỗi trước
                </b>{" "}
                để nâng cao dần khả năng của học sinh, hoặc lặp lại chuỗi bài
                tập cũ nếu kết quả cuối cùng vẫn chưa được cải thiện, giúp học
                sinh nắm vững kiến thức hơn.
              </p>
              <p
                className="is-underlined is-clickable"
                onClick={handleShowIntroduction}
                style={{ fontSize: "1rem", lineHeight: "normal" }}
              >
                Chi tiết về Cách thức hoạt động của Tính năng ‘Đường lên đỉnh
                IKMC’
              </p>
              <p
                className="has-text-weight-bold"
                style={{ fontSize: "1rem", lineHeight: "normal" }}
              >
                Bạn có chắc chắn muốn RESET và Tiếp tục Ôn luyện?
              </p>
              <div className="is-flex is-flex-direction-column is-flex-gap-4">
                <div
                  className="has-text-kurio-main-color-white has-text-weight-bold is-uppercase is-flex is-align-items-center is-justify-content-center is-clickable"
                  style={{
                    width: "25rem",
                    maxWidth: "25rem",
                    height: "4rem",
                    fontSize: "1.25rem",
                    lineHeight: "normal",
                    borderRadius: "1rem",
                    // border: "0.25rem solid #FAFBFC",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage:
                      "url('/images/exam-preparation/background-reset-button.svg')",
                    backgroundSize: "cover",
                  }}
                  onClick={handleReset}
                >
                  reset & tiếp tục ôn luyện
                </div>
                <SecondaryButtonLevelPage
                  style={{ width: "25rem", maxWidth: "25rem" }}
                  onClick={handleSetShowContinueToPractice}
                >
                  để lúc khác
                </SecondaryButtonLevelPage>
              </div>
            </div>
            {/* <div className="is-flex is-flex-direction-column is-flex-gap-5  ">
              {examsRoadMap &&
                examsRoadMap?.length > 0 &&
                examsRoadMap?.map((item, index) => {
                  return (
                    <div key={index}>
                      <MapExam
                        type={index === 0 ? 1 : 0 < index && index <= 3 ? 2 : 3}
                        // status={1}
                        index={item?.code}
                        currentScore={item?.currentScore}
                        maxScore={item?.maxScore}
                        scoreItem={item?.branches}
                      />
                    </div>
                  );
                })}
            </div> */}
          </div>
        </div>
      )}

      {!showContinueToPractice && !showBigExam && !showPopupProblemSet && (
        <div
          className="columns is-mobile m-auto p-0"
          style={{
            minHeight: "100vh",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            maxWidth: "80rem",
          }}
        >
          <div
            className=" is-flex is-align-items-center is-align-self-stretch"
            style={{
              minHeight: "100%",
              position: "fixed",
              paddingTop: "1.5rem",
            }}
          >
            <div
              className="column m-0 p-0 is-narrow"
              style={{
                maxWidth: "30.4375rem",
                width: "30.4375rem",
                height: "100%",
                // position: "fixed",
              }}
            >
              <div
                className="is-flex is-flex-direction-column is-justify-content-space-between"
                style={{
                  // position: "fixed",
                  width: "30.4375rem",
                  height: "calc(100% - 2.125rem)",
                  gap: "2rem",

                  // bottom: "0rem",
                }}
              >
                <div
                  className="is-clickable ml-7 is-flex is-flex-gap-3 is-align-items-center has-text-kurio-main-color-black has-text-weight-bold is-uppercase"
                  style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  onClick={backToHome}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                  >
                    <path
                      d="M5.82866 13.9995C5.53666 13.9995 5.24666 13.8725 5.04866 13.6265L0.22066 7.6265C-0.07734 7.2555 -0.07334 6.7255 0.23166 6.3595L5.23166 0.359495C5.58466 -0.0645046 6.21566 -0.121505 6.64066 0.231495C7.06466 0.584495 7.12166 1.2155 6.76766 1.6395L2.29266 7.0105L6.60766 12.3725C6.95366 12.8025 6.88566 13.4325 6.45466 13.7785C6.27066 13.9275 6.04866 13.9995 5.82866 13.9995Z"
                      fill="#0A2A66"
                    />
                  </svg>
                  <p>Quay Lại</p>
                </div>
                <div
                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                  style={{ width: "30.4375rem" }}
                >
                  <div
                    className="has-text-white  has-text-weight-bold is-flex is-flex-direction-column"
                    style={{ width: "14.69913rem", gap: "0.875rem" }}
                  >
                    <div className="is-uppercase">
                      <img
                        style={{
                          width: "14.69913rem",
                          height: "7.41rem",
                          objectFit: "contain",
                        }}
                        src="/images/semester-exam/text_banner_HK2.svg"
                        alt=""
                      />
                    </div>
                    <div className="is-flex is-flex-gap-4 is-align-items-flex-start">
                      <div
                        className="is-flex is-flex-gap-2 has-text-kurio-main-color-black has-text-weight-bold has-background-kurio-main-color-white is-clickable is-align-items-center"
                        style={{
                          padding: "0rem 0.75rem",
                          borderRadius: "1.5rem",
                          height: "2.25rem",
                          outline: "0.09375rem solid #FAFBFC",
                        }}
                        onClick={handleShowIntroduction}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M5.2 6.0002C5.2 4.4562 6.456 3.2002 8 3.2002C9.544 3.2002 10.8 4.4562 10.8 6.0002C10.8 7.26419 9.952 8.3226 8.8 8.6698V9.60019C8.8 10.0426 8.4424 10.4002 8 10.4002C7.5576 10.4002 7.2 10.0426 7.2 9.60019V8.00019C7.2 7.5578 7.5576 7.2002 8 7.2002C8.6616 7.2002 9.2 6.6618 9.2 6.0002C9.2 5.3386 8.6616 4.8002 8 4.8002C7.3384 4.8002 6.8 5.3386 6.8 6.0002C6.8 6.4426 6.4424 6.8002 6 6.8002C5.5576 6.8002 5.2 6.4426 5.2 6.0002ZM7.1998 12.0002C7.1998 11.5578 7.5574 11.2002 7.9998 11.2002C8.4422 11.2002 8.7998 11.5578 8.7998 12.0002C8.7998 12.4426 8.4422 12.8002 7.9998 12.8002C7.5574 12.8002 7.1998 12.4426 7.1998 12.0002ZM8 14.4001C4.4712 14.4001 1.6 11.5289 1.6 8.0001C1.6 4.4713 4.4712 1.6001 8 1.6001C11.5288 1.6001 14.4 4.4713 14.4 8.0001C14.4 11.5289 11.5288 14.4001 8 14.4001ZM8 0C3.5888 0 0 3.5888 0 8C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 8C16 3.5888 12.4112 0 8 0Z"
                            fill="#0A2A66"
                          />
                        </svg>
                        <p
                          className=""
                          style={{
                            fontSize: "0.875rem",
                            lineHeight: "normal",
                          }}
                        >
                          Hướng dẫn
                        </p>
                      </div>
                    </div>
                  </div>
                  <ImageFromUrl
                    objectId="admin/frontend-images/auth/image-left-signup.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="column is-flex-gap-5 is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            style={{
              padding: "6.25rem 0rem 2.5rem 0rem",
              marginLeft: "30.4375rem",
              maxWidth: "40.5625rem",
            }}
          >
            <div
              className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-relative"
              style={{ width: "35rem" }}
            >
              {((!checkPremium({
                userInfo: userInfo,
                screen: SCREEN_PRACTICE,
              }) &&
                examsRoadMap &&
                examsRoadMap?.length > 0 &&
                examsRoadMap[0]?.submitted &&
                examsRoadMap[0]?.problemSets[0]?.submitted &&
                examsRoadMap[0]?.problemSets[0]?.correctAnswers ===
                  examsRoadMap[0]?.problemSets[0]?.questionNumber) ||
                !token) && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "0rem",
                    right: "0rem",
                    zIndex: "3",
                  }}
                >
                  <div className="is-flex is-flex-direction-column is-relative">
                    <img
                      style={{
                        // objectFit: "cover",
                        width: "28.375rem",
                        height: "19.25rem",
                      }}
                      src={
                        token
                          ? "/images/exam-preparation/notification-upgrade-account.svg"
                          : "/images/exam-preparation/register-account.svg"
                      }
                      alt=""
                    />
                    <img
                      onClick={() => {
                        if (token) {
                          navigate("/upgrade");
                        } else {
                          dispatch(clearStateRoadMap());
                          localStorage.setItem("registerAnonymousUser", true);
                          navigate("/register");
                        }
                      }}
                      className="is-clickable"
                      style={{
                        width: "10rem",
                        height: "3.5rem",
                        position: "absolute",
                        top: "14rem",
                        left: "2.75rem",
                      }}
                      src={
                        token
                          ? "/images/hub/btn-premium.svg"
                          : "/images/exam-preparation/btn-register.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
              <div>
                {examsRoadMap &&
                  examsRoadMap?.length > 0 &&
                  examsRoadMap?.map((item, index) => {
                    return (
                      <div key={index}>
                        <RoadMapExam
                          type={
                            index === 0 ? 1 : 0 < index && index <= 3 ? 2 : 3
                          }
                          index={item.code}
                          title={item.name}
                          disabled={
                            checkPremium({
                              userInfo: userInfo,
                              screen: SCREEN_PRACTICE,
                            })
                              ? !item.accessible
                              : index === 0
                              ? !item.accessible
                              : !checkPremium({
                                  userInfo: userInfo,
                                  screen: SCREEN_PRACTICE,
                                })
                          }
                          currentScore={item.currentScore}
                          status={item.submitted ? 2 : 1}
                          maxScore={item.maxScore}
                          onClick={() =>
                            handleShowBigExam(
                              item._id,
                              item.takeRoadMapExamId,
                              item.submitted,
                              item.name
                            )
                          }
                        />
                        <div className="is-flex is-align-items-center is-justify-content-space-between">
                          <div
                            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                            style={{ padding: "0rem 2.4375rem" }}
                          >
                            {index < examsRoadMap?.length - 1 && (
                              <div
                                style={{
                                  height: "2.5rem",
                                  width: "0rem",
                                  border: "0.28125rem dashed #FAFBFC",
                                }}
                              ></div>
                            )}
                            {item?.problemSets?.map((problem, problemIndex) => {
                              return (
                                <div
                                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                                  key={problemIndex}
                                >
                                  <ProblemSet
                                    disabled={
                                      checkPremium({
                                        userInfo: userInfo,
                                        screen: SCREEN_PRACTICE,
                                      })
                                        ? !problem.accessible
                                        : problemIndex === 0
                                        ? !problem.accessible
                                        : !checkPremium({
                                            userInfo: userInfo,
                                            screen: SCREEN_PRACTICE,
                                          })
                                    }
                                    onClick={() =>
                                      showPopUpProblemSet(
                                        listRoadMap._id,
                                        item._id,
                                        problem._id,
                                        problem.takeRoadMapProblemSetId,
                                        problem.correctAnswers,
                                        problem.submitted,
                                        problem.questionNumber ===
                                          problem.correctAnswers
                                      )
                                    }
                                    status={
                                      problem.submitted
                                        ? problem.questionNumber ===
                                          problem.correctAnswers
                                          ? 3
                                          : 2
                                        : 1
                                    }
                                    currentScore={problem.correctAnswers}
                                    maxScore={problem.questionNumber}
                                    index={problem.code}
                                  />
                                  <div
                                    className="is-relative"
                                    style={{
                                      height: "2.5rem",
                                      width: "0rem",
                                      border: "0.28125rem dashed #FAFBFC",
                                      zIndex: 2,
                                    }}
                                  ></div>
                                </div>
                              );
                            })}
                          </div>

                          {/* {item?.accessible && (
                            <img
                              style={{
                                width: "12.03125rem",
                                height: "11.5625rem",
                              }}
                              src="/images/exam-preparation/box-chat-default.svg"
                              alt=""
                            />
                          )}

                          {!item?.accessible && (
                            <img
                              style={{
                                width: "12.03125rem",
                                height: "11.5625rem",
                              }}
                              src="/images/exam-preparation/box-chat-locked.svg"
                              alt=""
                            />
                          )} */}

                          {index < examsRoadMap?.length - 1 && (
                            <>
                              {item?.accessible ? (
                                item?.problemSets?.every(
                                  (problem) =>
                                    problem.submitted 
                                ) ? (
                                  <img
                                    style={{
                                      width: "12.03125rem",
                                      height: "11.5625rem",
                                    }}
                                    src="/images/exam-preparation/box-chat-finished.svg"
                                    alt=""
                                  />
                                ) : item?.accessible ? (
                                  <img
                                    style={{
                                      width: "12.03125rem",
                                      height: "11.5625rem",
                                    }}
                                    src="/images/exam-preparation/box-chat-default.svg"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    style={{
                                      width: "12.03125rem",
                                      height: "11.5625rem",
                                    }}
                                    src="/images/exam-preparation/box-chat-locked.svg"
                                    alt=""
                                  />
                                )
                              ) : (
                                <img
                                  style={{
                                    width: "12.03125rem",
                                    height: "11.5625rem",
                                  }}
                                  src="/images/exam-preparation/box-chat-locked.svg"
                                  alt=""
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {examsRoadMap &&
              examsRoadMap?.length > 0 &&
              examsRoadMap[examsRoadMap?.length - 1].submitted && (
                <div
                  className="has-text-kurio-main-color-white has-text-weight-bold is-uppercase is-flex is-align-items-center is-justify-content-center is-clickable"
                  style={{
                    width: "35rem",
                    maxWidth: "35rem",
                    height: "5rem",
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                    borderRadius: "1rem",
                    border: "0.25rem solid #FAFBFC",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundImage:
                      "url('/images/exam-preparation/background-continue-to-practice.svg')",
                    backgroundSize: "cover",
                  }}
                  onClick={handleSetShowContinueToPractice}
                >
                  tiếp tục ôn luyện
                </div>
              )}
          </div>
        </div>
      )}

      {showPopupProblemSet &&
        !loadingImage &&
        localStorage.getItem("takeRoadMapProblemSetId") && (
          <>
            <ProblemSetMainWrapper
              setShowPopupProblemSet={setShowPopupProblemSet}
            />
          </>
        )}

      {showBigExam &&
        !loadingImage &&
        localStorage.getItem("takeRoadMapExamId") && (
          <RoadMapExamMainWrapper setShowBigExam={setShowBigExam} />
        )}
    </BackgroundImageFromUrl>
  );
};

export default ExamPreparationHK2;
